$c4g-black: #011627;
$c4g-white: #FFFFFA;
$c4g-primary: #e69138;
$c4g-secondary: #3d85c6;
$c4g-warn: #b84843;
$c4g-separator: lighten($c4g-primary, 50%);
$c4g-separator-dark: darken($c4g-primary, 30%);
$c4g-border: darken(white, 15%);
$c4g-border-dark: lighten(black, 25%);
$main-sidenav-width: 250px;

$white: #f5f5f5;
$white2: #f2f2f2;
$dark-gray: #636363;
$dark-gray-2: #393939;
$dark: #040922;
