@use '@angular/material' as mat;
@import './variables';
@include mat.core();

// Fonts
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500&display=swap');

$fonts-config: mat.m2-define-typography-config(
  $headline-1:
    mat.m2-define-typography-level(112px, 112px, 300, 'Oswald', -0.0134em),
  $headline-2:
    mat.m2-define-typography-level(56px, 56px, 400, 'Oswald', -0.0089em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, 'Oswald', 0em),
  $headline-4:
    mat.m2-define-typography-level(34px, 40px, 400, 'Oswald', 0.0074em),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400, 'Oswald', 0em),
  $headline-6:
    mat.m2-define-typography-level(20px, 32px, 500, 'Oswald', 0.0075em),
  $subtitle-1:
    mat.m2-define-typography-level(16px, 28px, 400, 'Source Sans Pro', 0.0094em),
  $subtitle-2:
    mat.m2-define-typography-level(15px, 24px, 500, 'Source Sans Pro', 0.0067em),
  $body-2:
    mat.m2-define-typography-level(14px, 24px, 500, 'Source Sans Pro', 0.0179em),
  $body-1:
    mat.m2-define-typography-level(14px, 20px, 400, 'Source Sans Pro', 0.0179em),
  $button:
    mat.m2-define-typography-level(14px, 14px, 500, 'Source Sans Pro', 0.0893em),
  $caption:
    mat.m2-define-typography-level(12px, 20px, 400, 'Source Sans Pro', 0.0333em),
);

@include mat.all-component-typographies($fonts-config);

@include mat.typography-hierarchy($fonts-config);

.mat-h4,
.mat-typography h4 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  letter-spacing: -0.0089em;
}

.mat-h5,
.mat-typography h5 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: -0.0089em;
}

.mat-h6,
.mat-typography h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

@media (max-width: 768px) {
  .mat-h4,
  .mat-typography h4 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.235;
    letter-spacing: -0.0089em;
  }

  .mat-h5,
  .mat-typography h5 {
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.334;
    letter-spacing: -0.0089em;
  }

  .mat-h6,
  .mat-typography h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
}

// Foreground Elements

// Light Theme Text
$dark-text: $c4g-black;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-alternate: (
  main: $c4g-white,
  lighter: lighten($c4g-white, 20%),
  darker: darken($c4g-white, 20%),
);

$mat-light-theme-foreground: (
  base: $c4g-black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $c4g-black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $mat-alternate,
  icons: $mat-alternate,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: $c4g-white;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: $c4g-black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $mat-alternate,
  icons: $mat-alternate,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: $c4g-white;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten($c4g-black, 20%);
$dark-bg-alpha-4: rgba($c4g-black, 0.04);
$dark-bg-alpha-12: rgba($c4g-black, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: $c4g-black;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($c4g-white, 0.04);
$light-bg-alpha-12: rgba($c4g-white, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config

// Theme Config

body {
  --primary-color: $c4g-primary;
  --primary-lighter-color: lighten($c4g-primary, 30%);
  --primary-darker-color: darken($c4g-primary, 30%);
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: $c4g-primary,
  lighter: lighten($c4g-primary, 30%),
  darker: darken($c4g-primary, 30%),
  200: darken($c4g-primary, 50%),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: $c4g-secondary;
  --accent-lighter-color: lighten($c4g-secondary, 30%);
  --accent-darker-color: darken($c4g-secondary, 30%);
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: $c4g-secondary,
  lighter: lighten($c4g-secondary, 30%),
  darker: darken($c4g-secondary, 30%),
  200: darken($c4g-secondary, 50%),
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: $c4g-warn;
  --warn-lighter-color: lighten($c4g-warn, 30%);
  --warn-darker-color: darken($c4g-warn, 30%);
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: $c4g-warn,
  lighter: lighten($c4g-warn, 30%),
  darker: darken($c4g-warn, 30%),
  200: darken($c4g-warn, 50%),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      is-dark: true,
      foreground: $mat-dark-theme-foreground,
      background: $mat-dark-theme-background,
    ),
    // Only include `typography` and `density` in the default dark theme.
    typography: $fonts-config,
    density: 0,
  )
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      is-dark: false,
      foreground: $mat-light-theme-foreground,
      background: $mat-light-theme-background,
    ),
  )
);

@include mat.all-component-themes($light-theme);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  color: $c4g-primary !important;

  .mat-badge-content {
    font-family: 'Source Sans Pro';
  }
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $c4g-primary !important;
}

.ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-icon-button {
  background-color: lighten($c4g-black, 50%) !important;
}

.mat-datepicker-content {
  background-color: $white2 !important;
}

.mat-calendar-body-cell-content {
  background-color: $c4g-primary !important;
  color: white !important;
}

.mat-calendar-body-selected {
  background-color: $c4g-primary !important;
  color: white !important;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  background-color: lighten($c4g-black, 50%) !important;
}

button {
  background-color: $c4g-white;
}

.theme-container {
  margin: 1.5rem;
}

.mat-mdc-card {
  padding: 1.5rem;
  @include mat.elevation(4);
}

a {
  color: $c4g-primary;
  cursor: pointer;
}

c4g-chip {
  .content {
    background-color: $c4g-primary;
    color: $c4g-white;
  }
}

.c4g-chip-list {
  display: flex !important;
  flex-wrap: wrap !important;
  row-gap: 10px !important;
}

.mat-primary mat-icon {
  color: $c4g-white !important;
}

@media screen and (max-width: 768px) {
  .theme-container {
    margin: 1.5rem 0;
  }
}

.head-content-footer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.sticky-footer-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: rgba($dark-gray, 0.8);
  color: $white;
  z-index: 1000;
  text-align: center;
  padding: 1rem;
}

.sticky-head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 1000;
  background-color: rgba($dark-gray, 0.9);
  color: $white;
  text-align: center;
  padding: 1rem;
}

.scroll-content {
  position: absolute;
  top: 60px;
  bottom: 60px;
  padding: 12px;
  width: calc(100% - 24px);
  overflow: auto;
}

.sticky-footer {
  background-color: rgba($white2, 0.8);
  color: $dark;
}
.sticky-head {
  background-color: rgba($white2, 0.8);
  color: $dark;
}

body {
  --mdc-switch-track-height: 36px !important;
  --mdc-switch-track-shape: 18px !important;
  --mdc-switch-track-width: 44px !important;
}
